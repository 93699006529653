.sidebar{
    position: fixed;
    top: 73px;
    z-index: 1111;
    width: 200px;
    height: 100vh;
    background-color: #fff;
    border-right: 1px solid #bababa;
}
.sidemenu{
    border-left: 5px solid transparent;
    border-bottom: 1px solid #bababa;
}
.sidemenu:hover{
    background-color: #fae2ff;
}
.sidemenu.active{
    background-color: #fae2ff;
    border-left: 5px solid #ad05cf;
}
.sidemenu.active svg{
    color: #ad05cf;
}
.sidemenu.active p{
    color: #ad05cf;
}